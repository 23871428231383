import { Box, Checkbox, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import missionEditorStore, { Step, Object } from '../../../stores/missionEditorStore';
import { API } from '../../../api';
import { useEffect, useState, useMemo } from 'react';
import { ReactComponent as DistributionCabinet } from '../../../assets/distribution-cabinet.svg';
import { ReactComponent as Drainage } from '../../../assets/drainage.svg';
import { ReactComponent as Bench } from '../../../assets/bench-object.svg';
import { ReactComponent as ManholeCover } from '../../../assets/manhole-cover.svg';
import { ReactComponent as Roadblock } from '../../../assets/roadblock.svg';
import { ReactComponent as Transformer } from '../../../assets/transformer.svg';
import { ReactComponent as Trashcan } from '../../../assets/trashcan.svg';
import { ReactComponent as SlipperyPath } from '../../../assets/slippery-path.svg';
import { ReactComponent as SnowPlowed } from '../../../assets/snow-plowed.svg';
import { ReactComponent as UnplowedPath } from '../../../assets/unplowed-path.svg';
import { ReactComponent as RoadDefect } from '../../../assets/road-defect.svg';
import { ReactComponent as HimalayanBalsam } from '../../../assets/himalayan-balsam.svg';
import { ReactComponent as GiantHogweed } from '../../../assets/giant-hogweed.svg';
import { ReactComponent as Lupine } from '../../../assets/lupine.svg';
import { ReactComponent as JapaneseRose } from '../../../assets/japanese-rose.svg';
import { ReactComponent as RoughPath } from '../../../assets/rough-path.svg';
import { ReactComponent as DrainCover } from '../../../assets/drain-cover.svg';
import { ReactComponent as CurbsideDrainCover } from '../../../assets/curbside-drain-cover.svg';
import { ReactComponent as WaterValveCover } from '../../../assets/water-valve-cover.svg';
import { ReactComponent as JapaneseKnotweed } from '../../../assets/japanese-knotweed.svg';
import { ReactComponent as BusStop } from '../../../assets/bus_stop.svg';
import { ReactComponent as DisabledParkingSpot } from '../../../assets/disabled_parking_spot.svg';
import { ReactComponent as PublicTranspotSpot } from '../../../assets/public_transport_spot.svg';
import { ReactComponent as PedestrianCrossing } from '../../../assets/pedestrian_crossing.svg';

import { ReactComponent as CanadaGoldenrod } from '../../../assets/canada_goldenrod.svg';
import { ReactComponent as FalseSpiraea } from '../../../assets/false_spiraea.svg';
import { ReactComponent as Culvert } from '../../../assets/culvert.svg';
import { ReactComponent as SpanishSlug } from '../../../assets/spanish_slug.svg';

import { ReactComponent as LesserPeriwinkle } from '../../../assets/lesser_periwinkle.svg';
import { ReactComponent as Buddleia } from '../../../assets/buddleia.svg';
import { ReactComponent as ParrotsFeather } from '../../../assets/parrots_feather.svg';
import { ReactComponent as GoatsRue } from '../../../assets/goats_rue.svg';
import { ReactComponent as CherryLaurel } from '../../../assets/cherry_laurel.svg';
import { ReactComponent as ChameleonPlant } from '../../../assets/Chameleon_plant.svg';
import { ReactComponent as CypressSpurge } from '../../../assets/Cypress_spurge.svg';
import { ReactComponent as HimalayanHoneysuckle } from '../../../assets/Himalayan_honeysuckle.svg';
import { ReactComponent as ItalianArum } from '../../../assets/Italian_arum.svg';
import { ReactComponent as MexicanFleabane } from '../../../assets/Mexican_fleabane.svg';
import { ReactComponent as PurpletopVervain } from '../../../assets/Purpletop_vervain.svg';

function SvgCheckbox(props: { object: Object; checked: boolean; disabled: boolean; checkObject: (clickedObject: Object) => void }) {
	const { object, checkObject, checked, disabled } = props;
	const backgroundColor = disabled ? 'rgb(245,218,72,0.5)' : checked ? 'rgb(19,141,144,0.5)' : 'rgb(0,0,0,0.1)';
	const containerStyle = { width: '68px', height: '68px', background: backgroundColor };

	switch (object.ID) {
		case 1:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<ManholeCover />}
					icon={<ManholeCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 2:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Bench />}
					icon={<Bench style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 3:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<DistributionCabinet />}
					icon={<DistributionCabinet style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 4:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Roadblock />}
					icon={<Roadblock style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 5:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Trashcan />}
					icon={<Trashcan style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 6:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Drainage style={{ filter: 'none' }} />}
					icon={<Drainage style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 7:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Transformer />}
					icon={<Transformer style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 8:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<SlipperyPath style={{ filter: 'none' }} />}
					icon={<SlipperyPath style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 9:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<SnowPlowed />}
					icon={<SnowPlowed style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 10:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<UnplowedPath />}
					icon={<UnplowedPath style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 11:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<RoadDefect />}
					icon={<RoadDefect style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 12:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<HimalayanBalsam />}
					icon={<HimalayanBalsam style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 13:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<GiantHogweed />}
					icon={<GiantHogweed style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 14:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Lupine />}
					icon={<Lupine style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 15:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<JapaneseRose />}
					icon={<JapaneseRose style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 16:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<RoughPath />}
					icon={<RoughPath style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 17:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<DrainCover />}
					icon={<DrainCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 18:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CurbsideDrainCover />}
					icon={<CurbsideDrainCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 19:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<WaterValveCover />}
					icon={<WaterValveCover style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 20:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<JapaneseKnotweed />}
					icon={<JapaneseKnotweed style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 25:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CanadaGoldenrod />}
					icon={<CanadaGoldenrod style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 26:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<FalseSpiraea />}
					icon={<FalseSpiraea style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 27:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Culvert />}
					icon={<Culvert style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 28:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<SpanishSlug />}
					icon={<SpanishSlug style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 29:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<LesserPeriwinkle />}
					icon={<LesserPeriwinkle style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 30:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Buddleia />}
					icon={<Buddleia style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 31:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<ParrotsFeather />}
					icon={<ParrotsFeather style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 32:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<GoatsRue />}
					icon={<GoatsRue style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		case 33:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CherryLaurel />}
					icon={<CherryLaurel style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 34:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<ChameleonPlant />}
					icon={<ChameleonPlant style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 35:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CypressSpurge />}
					icon={<CypressSpurge style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 36:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<HimalayanHoneysuckle />}
					icon={<HimalayanHoneysuckle style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 37:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<ItalianArum />}
					icon={<ItalianArum style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 38:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<MexicanFleabane />}
					icon={<MexicanFleabane style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 39:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<PurpletopVervain />}
					icon={<PurpletopVervain style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 40:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<BusStop />}
					icon={<BusStop style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 41:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<DisabledParkingSpot />}
					icon={<DisabledParkingSpot style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 42:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<PublicTranspotSpot />}
					icon={<PublicTranspotSpot style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);
		case 43:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<PedestrianCrossing />}
					icon={<PedestrianCrossing style={{ filter: 'grayscale(100%)' }} />}
					onClick={() => checkObject(object)}
					style={containerStyle}
					disabled={disabled}
				/>
			);

		default:
			return <></>;
	}
}

export const ChoiceStep = observer((props: { step: Step }) => {
	const { step } = props;
	const { errors, setStepProperty } = missionEditorStore;
	const [objects, setObjects] = useState<Object[]>([]);
	const stepIndex = step.order - 1;
	// Stores if there an error when there has been a choice step with no objects selected
	const noSelectedObjectError = errors[`[${stepIndex}].choiceParams.objects`] || '';

	// Checks and unchecks an item by adding to the selected objects array
	const checkObject = (clickedObject: Object) => {
		if (!step.choiceParams) return;

		const index = step.choiceParams.objects.findIndex(object => object.ID == clickedObject.ID);

		if (index == -1) {
			step.choiceParams.objects.push({ ...clickedObject, stepChoiceId: 0 });
		} else {
			step.choiceParams.objects.splice(index, 1);
		}
	};

	// Fetch object options
	const fetchObjects = async () => {
		const res = await API.getObjects();
		setObjects(res.data.objects);
	};

	useEffect(() => {
		fetchObjects();
	}, []);

	// Gets a bit nicer name, the ones from the DB are machiney
	const getMarkerName = (type: number) => {
		switch (type) {
			case 1:
				return 'Manhole';
			case 2:
				return 'Bench';
			case 3:
				return 'Distribution Cabinet';
			case 4:
				return 'Roadblock';
			case 5:
				return 'Trashcan';
			case 6:
				return 'Drainage';
			case 7:
				return 'Transformer';
			case 8:
				return 'Slippery path';
			case 9:
				return 'Snow plowed';
			case 10:
				return 'Unplowed path';
			case 11:
				return 'Road defect';
			case 12:
				return 'Himalayan balsam';
			case 13:
				return 'Giant hogweed';
			case 14:
				return 'Lupine';
			case 15:
				return 'Japanese rose';
			case 16:
				return 'Rough path';
			case 17:
				return 'Drain cover';
			case 18:
				return 'Curbside drain cover';
			case 19:
				return 'Water valve cover';
			case 20:
				return 'Japanese knotweed';
			case 21:
				return 'Broken sound beacon';
			case 22:
				return 'Working sound beacon';
			case 23:
				return 'Pedestrian path';
			case 24:
				return 'Dropped curb';
			case 25:
				return 'Canada goldenrod';
			case 26:
				return 'False spiraea';
			case 27:
				return 'Culvert';
			case 28:
				return 'Spanish slug';
			case 29:
				return 'Lesser periwinkle';
			case 30:
				return 'Buddleia';
			case 31:
				return "Parrot's feather";
			case 32:
				return "Goat's rue";
			case 33:
				return 'Cherry laurel';
			case 34:
				return 'Chameleon plant';
			case 35:
				return 'Cypress spurge';
			case 36:
				return 'Himalayan honeysuckle';
			case 37:
				return 'Italian arum';
			case 38:
				return 'Mexican fleabane';
			case 39:
				return 'Purpletop vervain';
			case 40:
				return 'Bus stop';
		}
	};

	return (
		<Box>
			<div style={{ paddingTop: '12px' }}>
				<TextField
					label="Choice step title"
					value={step.choiceParams?.title}
					error={errors[`[${stepIndex}].choiceParams.title`]}
					fullWidth
					helperText={errors[`[${stepIndex}].choiceParams.title`]}
					onChange={e => setStepProperty(step, 'title', e.target.value)}
				/>
			</div>
			<Box sx={{ paddingTop: '20px', display: 'flex', gap: '10px', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start' }}>
				{objects.map(object => {
					const selectedObject = step.choiceParams?.objects.find(o => o.ID == object.ID);

					return (
						<SvgCheckbox
							checkObject={checkObject}
							checked={selectedObject ? true : false}
							key={object.ID}
							object={object}
							disabled={selectedObject?.stepChoiceId && selectedObject.stepChoiceId > 0 ? true : false}
						/>
					);
				})}
			</Box>
			{noSelectedObjectError && <div style={{ color: 'red', paddingTop: '12px' }}>{noSelectedObjectError}</div>}
			{step.choiceParams && step.choiceParams?.objects && step.choiceParams.objects.length > 0 && (
				<div style={{ paddingTop: '12px' }}>Object Values</div>
			)}
			<Box sx={{ paddingTop: '12px', display: 'flex', gap: '10px', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start' }}>
				{step.choiceParams
					? step.choiceParams.objects.map((o, index) => (
							<TextField
								fullWidth
								type="number"
								key={o.ID}
								label={`${getMarkerName(o.ID)} value`}
								helperText={errors[`[${stepIndex}].choiceParams.objects[${index}].value`] || ''}
								error={errors[`[${stepIndex}].choiceParams.objects[${index}].value`] || ''}
								disabled={o.stepChoiceId > 0}
								onChange={e => {
									step!.choiceParams!.objects[index].value = Number(e.target.value);
								}}
								value={step.choiceParams?.objects[index].value}
							/>
					  ))
					: null}
			</Box>
		</Box>
	);
});
